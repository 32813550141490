export const TERMS_AND_CONDITIONS_MOCK = {
  success: [
    {
      text: 'lore ipsum',
      version: '0.0.1',
      date: '20210720'
    }
  ],
  failures: [
    {
      path: 'path',
      statusCode: 400,
      code: 6,
      messageError: 'El servicio ha respondido con un error.'
    }
  ]
};
