import { CurrencyPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ufCurrency'
})
export class UfCurrencyPipe extends CurrencyPipe implements PipeTransform {
  transform(value: any, currencyCode?: string, display?: 'code' | 'symbol' | 'symbol-narrow' | string | boolean, digitsInfo?: string, locale?: string): any {
    return super.transform(
      value as number,
      currencyCode || 'CLF',
      display || '',
      digitsInfo || '1.0-0',
      locale || 'es-CL'
    );
  }
}
