export const SIMULATION_TOAST = {
  success: [
    {
      text: 'lore ipsum',
    }
  ],
  failures: [
    {
      path: 'path',
      statusCode: 400,
      code: 6,
      messageError: 'El servicio ha respondido con un error.'
    }
  ]
};
