import { HttpClient, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

import { from, Observable, throwError } from 'rxjs';

import { UNAUTHORIZED_HTTP_STATUS_CODE } from '@constants/authentication.constant';
import { environment } from '@env';
import { AuthorizationProvider } from '@providers/authorization/authorization';

@Injectable()
export class HttpInterceptorProvider implements HttpInterceptor {
  private whitelist = [
    'authentication/login/admin',
    'authentication/login/client',
    'authentication/login/contractor',
    'storage.googleapis.com/homein-public-uploads',
    'storage.googleapis.com/homein-private-uploads',
    'storage.googleapis.com/homein-prod-public-uploads',
    'storage.googleapis.com/homein-prod-private-uploads',
    'errorReporting'
  ];

  constructor(
    private authorizationProvider: AuthorizationProvider,
    public http: HttpClient,
    private firebaseAuth: AngularFireAuth,
  ) {
  }

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return from(this.handleAccess(request, next)
      .catch((error) => {
        if (error instanceof HttpErrorResponse && error.status === UNAUTHORIZED_HTTP_STATUS_CODE
          && !this.whitelist.some((prefix) => request.url.indexOf(prefix) > -1)) {
          this.authorizationProvider.setAuthorization({sessionActive: false});
        }

        return throwError(error).toPromise();
      }));
  }

  private async handleAccess(request: HttpRequest<any>, next: HttpHandler): Promise<HttpEvent<any>> {
    const token = await this.firebaseAuth.currentUser ? await (await this.firebaseAuth.currentUser).getIdToken() : null;
    if (token && !this.whitelist.some((prefix) => request.url.indexOf(prefix) > -1)) {
      if (request.url.startsWith(environment.BASE_CLOUD_FUNCTIONS)) {
        request = request.clone({setHeaders: {Authorization: `Bearer ${token}`}});
      } else {
        request = request.clone({setHeaders: {Authorization: token}});
      }
    }
    return next.handle(request).toPromise();
  }
}
