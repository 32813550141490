import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanLoad, Route, Router } from '@angular/router';
import { UserTypes } from '@constants/authentication.constant';
import {
  ADMIN_LOGIN_URL,
  CHANGE_PASSWORD_URL,
  CONTACT_FORM_URL,
  CONTRACTOR_LOGIN_URL,
  LOGIN_URL,
  ONBOARDING_URL
} from '@constants/url.constant';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanLoad {

  constructor(private firebaseAuth: AngularFireAuth, private router: Router) { }

  async canLoad(route: Route): Promise<boolean> {
    if (environment.USING_MOCKS) { return true; }

    let temporaryPassword: boolean;
    let termsVersion: string;
    let currentUserType: number[];
    const clientAuthenticated = await new Promise<boolean>(resolve =>
      this.firebaseAuth.onAuthStateChanged(async (user) => {
        if (user) {
          const token = await user.getIdTokenResult();
          if (token && token.claims) {
            temporaryPassword = token.claims.temporaryPassword;
            termsVersion = token.claims.termsVersion;
            currentUserType = token.claims.userType;
            const routePermission = this.getRoutePermissions(route);
            resolve(this.verifyCurrentUserPermission(currentUserType, routePermission));
          }
        } else if (route.path === CONTACT_FORM_URL) {
          resolve(true);
        } else { resolve(false); }
      })) ;

    if (!clientAuthenticated) {
      this.router.navigateByUrl(LOGIN_URL);
    } else if (temporaryPassword && !termsVersion && route.path !== ONBOARDING_URL && currentUserType.includes(UserTypes.CLIENT)) {
      this.router.navigateByUrl(ONBOARDING_URL);
    } else if (temporaryPassword && route.path !== CHANGE_PASSWORD_URL && (this.isAdminOrContractor(currentUserType) || termsVersion)) {
      this.router.navigateByUrl(CHANGE_PASSWORD_URL);
    }
    return clientAuthenticated;
  }

  private verifyCurrentUserPermission(currentUserType: UserTypes[], routePermissions: UserTypes[]): boolean {
    return routePermissions.some((routePermission: UserTypes) => currentUserType.includes(routePermission));
  }

  private getRoutePermissions(route: Route): UserTypes[] {
    if (route.data && route.data.userRoles) {
      return route.data?.userRoles as UserTypes[] || null;
    }
  }

  private isAdminOrContractor(userType: number[]) {
    return userType.includes(UserTypes.CONTRACTOR_ADMIN) || userType.includes(UserTypes.CONTRACTOR)
      || userType.includes(UserTypes.ADMIN);
  }
}
