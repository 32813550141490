import { Injectable } from '@angular/core';
import { ILegalBasis, ISimulationInformation, ISimulationModalData, ITermsAndConditions } from '@interfaces/firestore.interface';
import { UF } from '@interfaces/payment.interface';

@Injectable()
export abstract class FirebaseService {
    public abstract getTermsAndConditions(): Promise<ITermsAndConditions>;
    public abstract getSimulationInformation(): Promise<ISimulationInformation>;
    public abstract showSimulationModal(rut: string, creditId: number): Promise<ISimulationModalData>;
    public abstract updateSimulationModal(rut: string, creditIdList: number[]): Promise<boolean>;
    public abstract getUF(): Promise<UF>;
    public abstract getLegalPDFArray(): Promise<ILegalBasis[]>;
    public abstract updateUF(ufValue: number, ufDate: string): Promise<boolean>;
    public abstract showLiteClientModal(rut: string): Promise<boolean>;
    public abstract addRutToLiteClientModal(rut: string): Promise<boolean>;
}
