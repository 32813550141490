import { INavigationBar, INavigationOption } from '@interfaces/navigation.interface';
import {
  BLOG_TEXT,
  HOME_TEXT,
  HOW_IT_WORKS_TEXT,
  LOG_IN_TEXT, PROJECTS_TEXT, REFERRED_TEXT, SIMULATOR_TEXT
} from './header.constant';
import {
  ADMIN_CONTRACTOR_LIST,
  ADMIN_CREATE_USERS_URL,
  ADMIN_PAYMENTS_URL,
  ADMIN_PROJECTS_URL,
  BLOG_URL,
  CATEGORY_SELECTOR_URL,
  CHANGE_PASSWORD_URL,
  CLIENT_HOME_URL,
  COMPANY_HOME_URL,
  CONTRACTOR_CATEGORY_SELECTOR_URL,
  CONTRACTOR_DATA_URL,
  CONTRACTOR_PROJECTS_URL,
  HOME_URL,
  HOW_IT_WORKS_URL,
  INSPIRATIONS_CATALOG_URL,
  LOGIN_URL,
  LOGOUT_URL,
  PRIVATE_CATALOG_SELECTOR_URL,
  REFERRED_URL,
  SIMULATION_CONTRACTOR_LIST_URL,
  SIMULATION_URL,
  VIEW_INSPIRATION_URL,
} from './url.constant';

export const LOGGED_NAV_OPTIONS: INavigationOption[] = [
  { url: CLIENT_HOME_URL, title: 'Mis Proyectos', icon: 'home-outline' },
  { url: CHANGE_PASSWORD_URL, title: 'Cambiar clave', icon: 'key-outline' },
  { url: LOGOUT_URL, title: 'Cerrar Sesión', icon: 'log-out-outline' },
];

export const LOGGED_CONTRACTOR_NAV_OPTIONS: INavigationOption[] = [
  { url: COMPANY_HOME_URL, title: 'Mis Servicios', icon: 'list-outline' },
  { url: CONTRACTOR_PROJECTS_URL, title: 'Gestión de proyectos', icon: 'file-tray-full-outline' },
  { url: CHANGE_PASSWORD_URL, title: 'Cambiar clave', icon: 'key-outline' },
  { url: LOGOUT_URL, title: 'Cerrar Sesión', icon: 'log-out-outline' },
];

export const LOGGED_CONTRACTOR_ADMIN_NAV_OPTIONS: INavigationOption[] = [
  { url: CONTRACTOR_DATA_URL, title: 'Mi perfil', icon: 'person-outline' },
  ...LOGGED_CONTRACTOR_NAV_OPTIONS
];

export const LOGGED_ADMIN_NAV_OPTIONS: INavigationOption[] = [
  { url: ADMIN_PROJECTS_URL, title: 'Operaciones', icon: 'file-tray-stacked-outline' },
  { url: ADMIN_CREATE_USERS_URL, title: 'Gestión de Usuarios', icon: 'people-outline' },
  { url: ADMIN_CONTRACTOR_LIST, title: 'Perfiles proveedores', icon: 'build-outline' },
  { url: ADMIN_PAYMENTS_URL, title: 'Pagos', icon: 'card-outline' },
  { url: CHANGE_PASSWORD_URL, title: 'Cambiar clave', icon: 'key-outline' },
  { url: LOGOUT_URL, title: 'Cerrar Sesión', icon: 'log-out-outline' },
];

export const NOT_LOGGED_NAV_OPTIONS: INavigationOption[] = [
  { url: LOGIN_URL, title: LOG_IN_TEXT, icon: 'log-in-outline' },
  { url: '', title: HOME_TEXT, icon: 'home-outline' },
  { url: SIMULATION_URL, title: SIMULATOR_TEXT, icon: 'calculator-outline' },
  { url: REFERRED_URL, title: REFERRED_TEXT, icon: 'people-outline' },
  { url: PROJECTS_TEXT, title: PROJECTS_TEXT, icon: 'folder-outline' },
  { url: HOW_IT_WORKS_URL, title: HOW_IT_WORKS_TEXT, icon: 'caret-forward-circle-outline' },
  { url: BLOG_URL, title: BLOG_TEXT, icon: 'reader-outline' },
];

export const NAVIGATION_BAR_CATEGORY_SIMULATION: INavigationBar[] = [
  { text: 'Mejoras', clickeable: true, path: CLIENT_HOME_URL },
  { text: 'Catálogo', clickeable: false },
];

export const PRIVATE_NAVIGATION_BAR_CATEGORY_SIMULATION: INavigationBar[] = [
  { text: 'Catálogo', clickeable: false },
];

export const NAVIGATION_BAR_CATEGORY_CONTRACTORS_SIMULATION: INavigationBar[] = [
  { text: 'Mejoras', clickeable: true, path: CLIENT_HOME_URL },
  { text: 'Catálogo', clickeable: true, path: CATEGORY_SELECTOR_URL },
  { text: '', clickeable: false },
  { text: 'Proveedores', clickeable: false },
];

export const PUBLIC_NAVIGATION_BAR_CATEGORY_CONTRACTORS_SIMULATION: INavigationBar[] = [
  { text: 'Home', clickeable: true, path: HOME_URL },
  { text: '', clickeable: false },
  { text: 'Proveedores', clickeable: false },
];

export const PRIVATE_NAVIGATION_BAR_CATEGORY_CONTRACTORS_SIMULATION: INavigationBar[] = [
  { text: 'Categorías', clickeable: true, path: PRIVATE_CATALOG_SELECTOR_URL },
  { text: '', clickeable: false },
  { text: 'Proveedores', clickeable: false },
];

export const NAVIGATION_BAR_CATEGORY_SIMULATION_CONTRACTOR: INavigationBar[] = [
  { text: 'Nuevo servicio', clickeable: true, path: COMPANY_HOME_URL },
  { text: 'Categorías', clickeable: false },
];

export const NAVIGATION_BAR_INSPIRATION_ADD_PAGE: INavigationBar[] = [
  { text: 'Nuevo servicio', clickeable: true, path: COMPANY_HOME_URL },
  { text: 'Categorías', clickeable: true, path: CONTRACTOR_CATEGORY_SELECTOR_URL },
  { text: '', clickeable: false },
  { text: 'Detalle del proyecto', clickeable: false },
];

export const NAVIGATION_BAR_INSPIRATION_EDIT_PAGE: INavigationBar[] = [
  { text: 'Servicios e Inspiración', clickeable: true, path: COMPANY_HOME_URL },
  { text: 'Editar proyecto', clickeable: false },
];

export const NAVIGATION_BAR_CONTRACTOR_PROJECT_LIST: INavigationBar[] = [
  { text: 'Gestión de proyectos', clickeable: true, reloadPage: true, path: CONTRACTOR_PROJECTS_URL },
  { text: '', clickeable: true },
  { text: '', clickeable: false },
];

export const NAVIGATION_BAR_INSPIRATION_CATALOG_PAGE: INavigationBar[] = [
  { text: 'Mejoras', clickeable: true, path: CLIENT_HOME_URL },
  { text: 'Catálogo', clickeable: true, path: CATEGORY_SELECTOR_URL },
  { text: ':categoryName', clickeable: true, path: SIMULATION_CONTRACTOR_LIST_URL },
  { text: 'Proveedores', clickeable: true, path: SIMULATION_CONTRACTOR_LIST_URL },
];

export const NAVIGATION_BAR_INSPIRATION_VIEW_PAGE: INavigationBar[] = [
  { text: 'Mejoras', clickeable: true, path: CLIENT_HOME_URL },
  { text: 'Catálogo', clickeable: true, path: CATEGORY_SELECTOR_URL },
  { text: ':categoryName', clickeable: true, path: SIMULATION_CONTRACTOR_LIST_URL },
  { text: ':contractorName', clickeable: true, path: SIMULATION_CONTRACTOR_LIST_URL, reloadPage: true },
  { text: 'Proyectos', clickeable: true, path: INSPIRATIONS_CATALOG_URL, reloadPage: true },
  { text: ':inspirationName', clickeable: true, path: VIEW_INSPIRATION_URL },
];

export const NAVIGATION_BAR_CLIENT_PROJECT_DETAILS: INavigationBar[] = [
  { text: 'Inicio', clickeable: true, path: CLIENT_HOME_URL },
  { text: 'Proyectos', clickeable: false },
  { text: 'Detalles', clickeable: false },
];

export const PRIVATE_CATALOG = 'catalogo-privado';
