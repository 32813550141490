/* eslint-disable max-len */

import { IRangeValidation } from '@interfaces/input.interface';

export const MAX_MOBILE_WIDTH = 414;
export const SMALL_TABLET_WIDTH = 530;
export const MAX_TABLET_WIDTH = 1024;

export const DEFAULT_SERVICE_TIMEOUT = 20000;

export const EMAIL_REGEX = new RegExp(/^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,63}$/);
export const NUMBER_REGEX = new RegExp(/^[0-9]*$/);
export const NUMBER_DECIMAL_REGEX = new RegExp(/^[0-9.,]*$/);
export const PASSWORD_REGEX = new RegExp(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/);
export const URL_REGEX = new RegExp('([\/\w.:-])?');

export const ALLOWED_FILE_TYPES_DOCUMENT = ['application/pdf', 'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];

export const ALLOWED_FILE_TYPES_IMAGE = ['image/png', 'image/jpeg'];
export const MAX_FILE_SIZE_IMAGE = 8000000;
export const RESIZED_DIMENSIONS_INSPIRATION_IMAGE = '_1024x768.';

export const MAX_FILE_SIZE_CSV = 10000000;
export const ALLOWED_FILE_TYPES_CSV = [
  'text/csv',
  'application/vnd.ms-excel',
];

export const DOCUMENTS_NAMES = {
  priceProject: 'COTIZACION',
  contract: 'CONTRATO',
  other1: 'OTRO1',
  other2: 'OTRO2',
  photos: 'FOTOGRAFIAS',
  invoice: 'FACTURA'
};
export const ALLOWED_LENGTH_RUT_VALIDATION = {
  min: 11,
  max: 12
};

export const ALLOWED_LENGTH_CELLPHONE_VALIDATION = {
  min: 9,
  max: 9
};

export const ALLOWED_INSPIRATION_FORM_NUMBER_LENGHT_VALIDATION = {
  min: 1,
  max: 11
};

export const PHONE_VALIDATION: IRangeValidation = {
  min: 11,
  max: 11
};

export const EMAIL_VALIDATION: IRangeValidation = {
  min: 8,
  max: 40
};

export const MESSAGE_VALIDATION: IRangeValidation = {
  max: 1000
};


export const ALLOWED_LOGO_DIMENSIONS = {
  minWidth: 300,
  minHeight: 300,
  maxWidth: 500,
  maxHeight: 500,
};

export const INFORMATION_TOAST_STORAGE_KEY = 'information-toast';

export const TYC_TAB_VALUE = 'tyc';
export const LEGAL_TERMS_TAB_VALUE = 'legal-terms';
export const PROMOTIONS_BASIS_TAB_VALUE = 'legal-promotions';
