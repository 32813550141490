import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';

// Don't remove this import, it's used to load firebase needed modules
import firebase from 'firebase/compat/app';

import { LOGIN_MOCK } from '@common/mocks/login.mock';
import { STORAGE_URL_MOCK } from '@common/mocks/storage-url.mock';
import { USER_MOCK } from '@common/mocks/user.mock';
import { UI_VIEW_TYPES } from '@constants/authentication.constant';
import { environment } from '@env';
import { IMockup } from '@interfaces/http.interface';
import { IChangePassword } from '@interfaces/password.interface';
import { IStorageUrl } from '@interfaces/storage.interface';
import { ILiteClient, IUser } from '@interfaces/user.interface';
import { AuthorizationProvider } from '@providers/authorization/authorization';
import { HttpService } from '@services/http/http.service';

interface IActivateAccount {
  success: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  constructor(
    private http: HttpService,
    private authorizationProvider: AuthorizationProvider,
    public firebaseAuth: AngularFireAuth
  ) {
  }

  public async login(username: string, password: string, userType: number): Promise<void> {
    switch (userType) {
    case UI_VIEW_TYPES.client:
      return this.loginClient(username, password);
    case UI_VIEW_TYPES.contractor:
      return this.loginContractor(username, password);
    case UI_VIEW_TYPES.admin:
      return this.loginHomeInAdmin(username, password);
    }
  }

  private async loginHomeInAdmin(email: string, password: string): Promise<void> {
    const data = {email, password};
    const url = `${environment.BASE_BACKEND}authentication/login/admin`;
    return this.http.post<{ token: string }>(url, data, LOGIN_MOCK).toPromise()
      .then(async (response) => await this._setCurrentUserData(response.token));
  }

  private async loginClient(rut: string, password: string): Promise<void> {
    const data = {rut, password};
    const url = `${environment.BASE_BACKEND}authentication/login/client`;
    return this.http.post<{ token: string }>(url, data, LOGIN_MOCK).toPromise()
      .then(async (response) => await this._setCurrentUserData(response.token));
  }

  private async loginContractor(email: string, password: string): Promise<void> {
    const data = {email, password};
    const url = `${environment.BASE_BACKEND}authentication/login/contractor`;
    return this.http.post<{ token: string }>(url, data, LOGIN_MOCK).toPromise()
      .then(async (response) => await this._setCurrentUserData(response.token));
  }

  public async changePassword(changePassword: IChangePassword): Promise<void> {
    const url = `${environment.BASE_BACKEND}authentication/change-password`;
    return this.http.post<{ token: string }>(url, changePassword, {} as IMockup).toPromise()
      .then(async (response) => await this._setCurrentUserData(response.token));
  }

  public async recoverPassword(email: string): Promise<void> {
    const data = {email};
    const url = `${environment.BASE_BACKEND}authentication/recover-password`;
    return this.http.post(url, data, {} as IMockup).toPromise() as Promise<void>;
  }

  public async createLiteClient(data: ILiteClient): Promise<void> {
    const url = `${environment.BASE_BACKEND}authentication/register-lite-client`;
    return this.http.post(url, data, {} as IMockup).toPromise() as Promise<void>;
  }

  public async getUser(): Promise<IUser | null> {
    const clientToken = await this.firebaseAuth.currentUser.then((res) => {
      if (res) {
        return res.getIdTokenResult();
      }
      return undefined;
    });
    if (!clientToken) {
      return null;
    }
    const client = clientToken.claims;
    const clientRut = await this.getUserRut();

    return {
      rut: clientRut,
      name: client.name,
      middleName: client.middleName,
      lastName: client.lastName,
      motherLastName: client.motherLastName,
      email: client.email,
      cellphoneNumber: client.cellphoneNumber,
      userType: client.userType,
      temporaryPassword: client.temporaryPassword
    } as IUser;
  }

  public async getUserRut(): Promise<string> {
    return await this.firebaseAuth.currentUser.then((res) => res.uid);
  }

  public async logout(): Promise<void> {
    await this.firebaseSignOut();
    this.authorizationProvider.setAuthorization({sessionActive: false});
  }

  public async onboardUser(rut: string, password: string, passwordConfirmation: string,
    cellphoneNumber: string, email: string): Promise<void> {

    const data = {password, passwordConfirmation, cellphoneNumber, email};
    const url = `${environment.BASE_BACKEND}authentication/${rut}/onboard`;
    return this.http.post<{ token: string }>(url, data, LOGIN_MOCK).toPromise()
      .then(async (response) => await this._setCurrentUserData(response.token));
  }

  public async getWriteStorageSignedUrl(rut: string, fileName: string,
    contentType: string, bucketScope: string, folderName: string, companyRut?: string): Promise<IStorageUrl> {
    const params = new HttpParams()
      .set('fileName', fileName)
      .set('bucketScope', bucketScope)
      .set('folderName', folderName)
      .set('contentType', contentType)
      .set('companyRut', companyRut);
    const url = `${environment.BASE_BACKEND}authentication/${rut}/write-storage-url`;
    return this.http.get(url, STORAGE_URL_MOCK, params).toPromise() as Promise<IStorageUrl>;
  }

  public async getReadStorageSignedUrl(rut: string, fileLocationUrl: string): Promise<IStorageUrl> {
    const params = new HttpParams()
      .set('fileLocationUrl', fileLocationUrl);
    const url = `${environment.BASE_BACKEND}authentication/${rut}/read-storage-url`;
    return this.http.get(url, STORAGE_URL_MOCK, params).toPromise() as Promise<IStorageUrl>;
  }

  public async firebaseSignOut(): Promise<void> {
    await this.firebaseAuth.signOut().catch(() => {
    });
  }

  public activateLiteClientAccount(liteClientId: number): Promise<IActivateAccount> {
    const url = `${environment.BASE_BACKEND}authentication/activate-account/${liteClientId}`;
    return this.http.patch(url, null, null).toPromise() as Promise<IActivateAccount>;
  }

  //TODO: Refactorizar
  public clientLoginAsAdmin(clientRut: string, adminRut) {
    const url = `${environment.BASE_BACKEND}authentication/login/impersonate/client/${clientRut}?rut=${adminRut}`;
    return this.http.get(url, null, null).toPromise() as Promise<any>;
  }

  public async impersonateClient(clientToken: string): Promise<void> {
    await this._setCurrentUserData(clientToken);
  }

  private async _setCurrentUserData(token: string) {
    await this.firebaseSignOut();
    if (environment.USING_MOCKS) {
      return;
    }
    await this.firebaseAuth.signInWithCustomToken(token);
  }
}
