import { Observable, of, throwError } from 'rxjs';
import { delay, timeout } from 'rxjs/operators';
import {
  HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DEFAULT_SERVICE_TIMEOUT } from '@constants/business.constant';
import { IHttpParams, IMockup } from '@interfaces/http.interface';
import { environment } from '@env';

@Injectable({
  providedIn: 'root'
})

export class HttpService {
  private httpSuccessCode = 200;
  private httpFailureCode = 400;
  private headers = new HttpHeaders();
  private params = new HttpParams();

  constructor(private http: HttpClient) { }

  public delete(url: string, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'delete', url, mockup, data: false, customHeaders });
  }

  public get<T>(url: string, mockup: IMockup, customParams?: HttpParams, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'get', url, mockup, customParams, data: false, customHeaders });
  }

  public post<T>(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<T> {
    return this.httpCall({ method: 'post', url, mockup, data, customHeaders });
  }

  public put(url: string, data: any, mockup: IMockup, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'put', url, mockup, data, customHeaders });
  }

  public patch(url: string, data: any, mockup: IMockup, timeoutSetting?: number, customHeaders?: HttpHeaders): Observable<any> {
    return this.httpCall({ method: 'patch', url, mockup, data, timeoutValue: timeoutSetting, customHeaders });
  }

  private httpCall({ method, url, mockup, data, customHeaders, timeoutValue, customParams }: IHttpParams): Observable<any> {
    const header = customHeaders ? customHeaders : this.headers;
    const params = customParams ? customParams : this.params;
    timeoutValue = timeoutValue ? timeoutValue : DEFAULT_SERVICE_TIMEOUT;
    if (environment.USING_MOCKS) { return this.mockedResponse(mockup); }
    if (method === 'delete' || method === 'get') { return this.http[method](url, { headers: header, params })
      .pipe(timeout(timeoutValue));
    }

    return this.http[method]<any>(url, data, { headers: header }).pipe(timeout(timeoutValue));
  }

  private mockedResponse(mockup: IMockup): Observable<any> {
    const responseStatus = (mockup.failures && (Math.random() > environment.MOCK_SUCCESS_RATE)) ? 'failures' : 'success';
    const responseOptionsParams = { status: responseStatus === 'success' ? this.httpSuccessCode : this.httpFailureCode };

    if (responseStatus === 'success') { responseOptionsParams['body'] = mockup[responseStatus]; }
    else { responseOptionsParams['error'] = mockup[responseStatus]; }

    const response = new HttpResponse(responseOptionsParams);
    const responseError = new HttpErrorResponse(responseOptionsParams);
    return responseStatus === 'success' ? of(response.body).pipe(delay(environment.MOCK_DELAY_MILLISECONDS))
      : throwError(responseError.error);
  }
}
