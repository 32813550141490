import { Component } from '@angular/core';
import { IGenericModal } from '@interfaces/modal.interface';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-generic-modal',
  templateUrl: './generic-modal.component.html',
  styleUrls: ['./generic-modal.component.scss'],
})
export class GenericModalComponent {
  public data: IGenericModal;

  constructor(private modalCtrl: ModalController){}

  public dismissModal() {
    this.modalCtrl.dismiss();
  }
}
