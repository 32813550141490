export const STORAGE_URL_MOCK = {
  success: [
    {
      url: 'storage.googleapis.com/homein-mock-bucket'
    }
  ],
  failures: [
    {
      path: 'path',
      statusCode: 400,
      code: 6,
      messageError: 'El servicio ha respondido con un error.'
    }
  ]
};
