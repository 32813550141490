export const UI_VIEW_TYPES = {
  admin: 1,
  client: 2,
  contractor: 3
};

export const USER_TYPES = {
  admin: 1,
  client: 2,
  contractorAdmin: 3,
  contractor: 4,
};

/* eslint-disable @typescript-eslint/naming-convention */
export enum UserTypes {
  ADMIN = USER_TYPES.admin,
  CLIENT = USER_TYPES.client,
  CONTRACTOR_ADMIN = USER_TYPES.contractorAdmin,
  CONTRACTOR = USER_TYPES.contractor,
}

export const UNAUTHORIZED_HTTP_STATUS_CODE = 401;
export const WRONG_PASSWORD = 40000;
export const WRONG_TEMPORAL_PASSWORD = 40001;
export const PASSWORD_BLOCKED_ERROR_CODE = 400002;
export const WRONG_ACTUAL_PASSWORD = 400007;
export const EMAIL_NOT_FOUND = 400008;
export const PAYMENT_CSV_AMOUNT_COLUM_ERROR = 400009;
export const LITE_CLIENT_ALREADY_REGISTERED_ERROR = 400012;
export const LITE_CLIENT_ACCOUNT_NOT_ACTIVATED = 'LITE_CLIENT_ACCOUNT_NOT_ACTIVATED';

