/* eslint-disable @typescript-eslint/naming-convention */

import { IEnvironment } from 'src/app/common/interfaces/environment.interface';

export const environment: IEnvironment = {
  ENV: 'qa',
  BASE_BACKEND: 'https://qa-api.homein.cl/',
  BASE_CLOUD_FUNCTIONS: 'https://us-central1-homein-dev-qa.cloudfunctions.net',
  GOOGLE_ANALYTICS_ID: 'G-M3LKN3KQ7X',
  GOOGLE_TAG_MANAGER_ID: '',
  USING_MOCKS: false,
  MOCK_SUCCESS_RATE: 1,
  MOCK_DELAY_MILLISECONDS: 1000,
  FIREBASE_CONFIG: {
    apiKey: 'AIzaSyAxrqJV2haMaohkxQPd2yPo9R_J3SRAPW0',
    appId: '1:679152664148:web:59a19f54a81af6629fb7c1',
    authDomain: 'homein-dev-qa.firebaseapp.com',
    projectId: 'homein-dev-qa',
    storageBucket: 'homein-dev-qa.appspot.com',
  }
};
