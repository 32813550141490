import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { USER_TYPES } from '@constants/authentication.constant';
import { ADMIN_LOGIN_URL, CONTRACTOR_LOGIN_URL, LOGIN_URL } from '@constants/url.constant';
import { AuthenticationService } from '@services/authentication/authentication.service';

@Injectable()
export class SessionProvider {
  private finishSessionTimeout: number;
  private refreshTokenInterval: number;
  private activeSession: boolean;

  constructor(
    private router: Router,
    private authenticationService: AuthenticationService,
  ) { }

  public startSession() {
    if (this.activeSession) { return; }

    this.activeSession = true;
  }

  public async finishSession() {
    clearInterval(this.refreshTokenInterval);
    clearTimeout(this.finishSessionTimeout);
    const user = await this.authenticationService.getUser();
    this.authenticationService.firebaseSignOut();
    this.activeSession = false;
    if (user.userType.includes(USER_TYPES.contractor) || user.userType.includes(USER_TYPES.contractorAdmin)) {
      this.router.navigateByUrl(CONTRACTOR_LOGIN_URL);
    } else if (user.userType.includes(USER_TYPES.admin)) {
      this.router.navigateByUrl(ADMIN_LOGIN_URL);
    } else {
      this.router.navigateByUrl(LOGIN_URL);
    }
  }

}
