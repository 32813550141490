import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { CanLoad } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SessionGuard implements CanLoad {

  constructor(private firebaseAuth: AngularFireAuth) { }

  async canLoad(): Promise<boolean> {
    const clientAuthenticated = await new Promise<boolean>((resolve) =>
      this.firebaseAuth.onAuthStateChanged(() => resolve(true)));
    return clientAuthenticated;
  }
}
