export const INSCRIPTION_TEXT = 'Inscríbete';
export const PROJECTS_TEXT = 'Proveedores y servicios';
export const HOW_IT_WORKS_TEXT = '¿Cómo funciona?';
export const CONTACT_TEXT = 'Contacto';
export const LOG_IN_TEXT = 'Acceso Clientes';
export const BLOG_TEXT = 'Blog';
export const HOME_TEXT = 'Inicio';
export const SIMULATOR_TEXT = 'Simula tu proyecto';
export const REFERRED_TEXT = 'Recomienda Homein';
export const HEADER_MENU_BREAKPOINT = 1100;
